import APIService from '@content-ignite/core/system/APIService'

const Branding = (): Promise<IConfig> => {
  const targetVersion = '1'
  const localConfig = localStorage.getItem('_ci_config')
  const version = localStorage.getItem('_ci_version')
  if (localConfig !== null && version === targetVersion) {
    return new Promise((resolve, reject) => {
      resolve(JSON.parse(localConfig))
    })
  } else {
    const Auth = new APIService()
    return Auth.fetch('/v1/account/branding', {})
      .then(config => config.config)
      .then(config => {
        localStorage.setItem('_ci_config', JSON.stringify(config))
        localStorage.setItem('_ci_version', targetVersion)
        return config
      })
  }
}

export default Branding
