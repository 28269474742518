import './assets/css/index.css'

import Branding from './workers/Branding'
import CircularProgress from '@material-ui/core/CircularProgress'
import CssBaseline from '@material-ui/core/CssBaseline'
import CustomTheme from '@content-ignite/core/CustomTheme'
import ErrorBoundary from '@content-ignite/core/system/ErrorBoundary'
import PrivateRoute from './components/PrivateRoute'
import ReactDOM from 'react-dom'
import { RouteComponentProps } from 'react-router'
import reportWebVitals from './workers/reportWebVitals'
import { routes } from './routes'

import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

const ForgotPassword = lazy(() => import('./routes/ForgotPassword'))
const Login = lazy(() => import('./routes/Login'))
const NotFoundPage = lazy(() => import('./routes/NotFound'))
const ResetPassword = lazy(() => import('./routes/ResetPassword'))

const App: React.FC = () => {
  const [config, setConfig] = useState<null | IConfig>(null)

  useEffect(() => {
    Branding().then((conf: IConfig) => {
      setConfig(conf)
      // set custom favicon for white labeled systems
      if (
        conf.logo_flat &&
        window.location.href.indexOf('contentignite') === -1
      ) {
        const favico = document.querySelector(
          "link[rel*='icon']"
        ) as null | HTMLLinkElement
        if (favico) {
          favico.href = conf.logo_flat
        }
      }
    })
  }, [])

  const loadingPage = () => (
    <div className="fullPage">
      <CircularProgress size={80} />
    </div>
  )

  if (config === null) {
    return loadingPage()
  }
  const LoginPage = <T extends RouteComponentProps<{}>>(props: T) => (
    <Login config={config} {...props} />
  )
  const ForgotPasswordPage = <T extends RouteComponentProps<{}>>(props: T) => (
    <ForgotPassword config={config} {...props} />
  )
  const ResetPasswordPage = <T extends RouteComponentProps<{}>>(props: T) => (
    <ResetPassword config={config} {...props} />
  )
  return (
    <Router>
      <CustomTheme config={config}>
        <CssBaseline />
        <Suspense fallback={loadingPage()}>
          <Switch>
            {routes.map((route, i) => (
              <PrivateRoute config={config} key={i} {...route} />
            ))}
            <Route
              exact={true}
              path="/forgot-password"
              component={ForgotPasswordPage}
            />
            <Route
              exact={true}
              path="/reset-password"
              component={ResetPasswordPage}
            />
            <Route exact={true} path="/login" component={LoginPage} />
            <PrivateRoute config={config} component={NotFoundPage} />
          </Switch>
        </Suspense>
      </CustomTheme>
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// eslint-disable-next-line no-console
reportWebVitals(console.log)
