import { lazy } from 'react'

export const routes: IPrivRouteProps[] = [
  {
    component: lazy(
      () => import(/* webpackChunkName: "home" */ './routes/Dash')
    ),
    exact: true,
    path: '/'
  },
  {
    component: lazy(
      () => import(/* webpackChunkName: "account" */ './routes/Account')
    ),
    exact: true,
    path: '/account'
  },
  {
    component: lazy(
      () =>
        import(/* webpackChunkName: "organisations" */ './routes/Organisations')
    ),
    exact: true,
    path: '/organisation'
  },
  {
    component: lazy(
      () =>
        import(/* webpackChunkName: "organisation" */ './routes/Organisation')
    ),
    exact: true,
    path: '/organisation/:id'
  },
  {
    component: lazy(
      () => import(/* webpackChunkName: "users" */ './routes/Users')
    ),
    exact: true,
    path: '/user'
  },
  {
    component: lazy(
      () => import(/* webpackChunkName: "user" */ './routes/User')
    ),
    exact: true,
    path: '/user/:id'
  },
  {
    component: lazy(
      () => import(/* webpackChunkName: "funneluser" */ './routes/FunnelSingle')
    ),
    exact: true,
    path: '/funnel/user/:id'
  },
  {
    component: lazy(
      () =>
        import(/* webpackChunkName: "funnelsplit" */ './routes/FunnelSplitView')
    ),
    exact: true,
    path: '/funnel/split/:from1/:to1//:from2/:to2'
  },
  {
    component: lazy(
      () => import(/* webpackChunkName: "funnel" */ './routes/FunnelView')
    ),
    exact: true,
    path: '/funnel'
  },
  {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "paidmediareportedit" */ './routes/ReportPaidMediaBuilder'
        )
    ),
    exact: true,
    path: '/report/paid-media/:id/edit'
  },
  {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "paidmediareportlist" */ './routes/ReportPaidMediaList'
        )
    ),
    exact: true,
    path: '/report/paid-media'
  },
  {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "paidmediareportnew" */ './routes/ReportPaidMediaBuilder'
        )
    ),
    exact: true,
    path: '/report/paid-media/new'
  },
  {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "paidmediareport" */ './routes/ReportPaidMedia'
        )
    ),
    exact: true,
    path: '/report/paid-media/:id'
  },
  {
    component: lazy(
      () => import(/* webpackChunkName: "reportdeck" */ './routes/ReportDeck')
    ),
    exact: true,
    path: '/report/deck/:master_id?/:report?'
  },
  {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "accountConnections" */ './routes/AccountConnections'
        )
    ),
    exact: true,
    path: '/accounts'
  },
  {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "masterCampaignList" */ './routes/MasterCampaignList'
        )
    ),
    exact: true,
    path: '/paid-media'
  },
  {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "masterCampaignEdit" */ './routes/MasterCampaignEdit'
        )
    ),
    exact: true,
    path: '/paid-media/new'
  },
  {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "masterCampaignEdit" */ './routes/MasterCampaignEdit'
        )
    ),
    exact: true,
    path: '/paid-media/:master_id/edit'
  },
  {
    component: lazy(
      () =>
        import(/* webpackChunkName: "creativeView" */ './routes/CreativeView')
    ),
    exact: true,
    path: '/paid-media/:master_id/creative/:id'
  },
  {
    component: lazy(
      () =>
        import(/* webpackChunkName: "creativeEdit" */ './routes/CreativeEdit')
    ),
    exact: true,
    path: '/paid-media/:master_id/creative/:type(text|image|video|carousel)/new'
  },
  {
    component: lazy(
      () =>
        import(/* webpackChunkName: "creativeEdit" */ './routes/CreativeEdit')
    ),
    exact: true,
    path: '/paid-media/:master_id/creative/:id/edit'
  },
  {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "creativePreview" */ './routes/CreativePreview'
        )
    ),
    exact: true,
    path: '/paid-media/:master_id/creative/:id/preview/:platform'
  },
  {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "masterCampaign" */ './routes/MasterCampaign'
        )
    ),
    exact: true,
    path: '/paid-media/:master_id'
  },
  {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "masterCampaign" */ './routes/MasterCampaign'
        )
    ),
    exact: true,
    path: '/paid-media/:master_id/creatives'
  },
  {
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "masterCampaign" */ './routes/MasterCampaign'
        )
    ),
    exact: true,
    path: '/paid-media/:master_id/mapped-creatives'
  },
  {
    component: lazy(
      () =>
        import(/* webpackChunkName: "campaignEdit" */ './routes/CampaignEdit')
    ),
    exact: true,
    path: '/paid-media/:master_id/new'
  },
  {
    component: lazy(
      () =>
        import(/* webpackChunkName: "campaignEdit" */ './routes/CampaignEdit')
    ),
    exact: true,
    path: '/paid-media/:master_id/:id/edit'
  },
  {
    component: lazy(
      () => import(/* webpackChunkName: "urlTool" */ './routes/URLTool')
    ),
    exact: true,
    path: '/paid-media-url-tool'
  }
]
